import { FC, ReactNode } from 'react';

import { AuthPage } from '@/pages/authorization';

import { useAuth, useProfileStore } from '@/entities/profile';

import { Loader } from '@/shared/components';

export const withAuthProvider = (Component: () => ReactNode): FC => {
  return () => {
    const isLoading = useProfileStore.use.isLoading();
    const profile = useProfileStore.use.profile();

    useAuth();

    if (isLoading) {
      return <Loader />;
    }

    // В типах TProfileDTO, но может быть null. Так сделано чтобы в дальнейшем опустить проверку на наличие profile
    if (!profile) {
      return <AuthPage />;
    }

    return <Component />;
  };
};
