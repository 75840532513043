import { http } from '../client.ts';

import { ELanguages } from '@/shared/config';

export type TModuleDTO = {
  name: string;
  desc: string;
  icon: boolean;
  show: boolean;
};
export type TUserDTO = {
  id: number;
  email: string;
  lang: ELanguages;
  createdAt: string;
  updatedAt: string;
};
export type TProfileDTO = {
  id: number;
  firstName: string;
  lastName: string;
  group: string;
  access: string;
  photoUrl: string | null;
  modules: TModuleDTO[];
  widgets: string[];
  user: TUserDTO;
};
export type TProfileResponse = { profile: TProfileDTO };
export type TSSOResponse = { token: string } & TProfileResponse;

export const fetchProfile = async () => {
  const response = await http.get<TProfileResponse>('profile');
  return response.data;
};

export const fetchSSO = async (code: string) => {
  const response = await http.get<TSSOResponse>(`auth/user?code=${code}`);
  return response.data;
};
