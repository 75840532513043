import { Trans } from '@lingui/macro';
import { useQuery } from '@tanstack/react-query';
import { Flex, Typography } from 'antd';
import axios from 'axios';

import { getExamLines } from '../lib/getExamLines.tsx';

import { examQueries } from '@/entities/exam';

import {
  AxiosErrorBoundary,
  MultilineCard,
  NoContent,
  WidgetSkeleton,
} from '@/shared/components';

const widgetTitle = (
  <Typography.Title level={4}>
    <Trans>Экзамен</Trans>
  </Typography.Title>
);

export const Exam = () => {
  const { data, isPending, error } = useQuery(examQueries.closest());

  if (!isPending && data && Object.keys(data).length > 0) {
    return (
      <Flex vertical gap={24}>
        {widgetTitle}

        <MultilineCard
          title='Экзаменационная сессия'
          lines={getExamLines(data)}
        />
      </Flex>
    );
  }

  if (error && axios.isAxiosError(error)) {
    return (
      <Flex vertical gap={24}>
        {widgetTitle}
        <AxiosErrorBoundary error={error} />
      </Flex>
    );
  }

  if (!isPending && (!data || Object.keys(data).length === 0)) {
    return (
      <Flex vertical gap={24}>
        {widgetTitle}

        <NoContent
          title='Ближайшие экзамены отсутствуют'
          description='Обратитесь к куратору для уточнения информации'
        />
      </Flex>
    );
  }

  return <WidgetSkeleton />;
};
