import { Flex, Tag, Typography } from 'antd';

import { IdcardFilled, ReadFilled } from '@ant-design/icons';

import { useStyles } from './styles.ts';

import { TScheduleLessonDTO } from '@/shared/api';

type TLessonContentProps = {
  lesson: TScheduleLessonDTO;
  isCard?: boolean;
};

export const LessonContent = ({
  lesson,
  isCard = true,
}: TLessonContentProps) => {
  const { styles } = useStyles();

  return (
    <Flex vertical gap={12}>
      <Flex align='center' justify='space-between'>
        <Typography.Text type='secondary'>{lesson.period}</Typography.Text>

        <Tag
          color={lesson.exam === 0 ? 'orange' : 'cyan'}
          className={styles.tag}
        >
          {lesson.exam === 0 ? 'Событие' : 'Занятие'}
        </Tag>
      </Flex>

      <Flex vertical gap={8}>
        {isCard ? (
          <Typography.Text strong className={styles.discipline}>
            {lesson.discipline}
          </Typography.Text>
        ) : (
          <Typography.Title level={3}>{lesson.discipline}</Typography.Title>
        )}
        <Typography.Text type='secondary'>
          <ReadFilled className={styles.icon} />
          {lesson.teacher}
        </Typography.Text>
        {lesson.online ? (
          <Typography.Link
            href={lesson.route}
            target='_blank'
            rel='noreferrer'
            className={styles.externalLink}
          >
            <IdcardFilled className={styles.icon} />
            Онлайн
          </Typography.Link>
        ) : (
          <Typography.Text type='secondary'>
            <IdcardFilled className={styles.icon} />
            {lesson.route}
          </Typography.Text>
        )}
      </Flex>
    </Flex>
  );
};
