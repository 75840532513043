import { createStyles } from 'antd-style';

export const useStyles = createStyles(({ css, token, prefixCls }) => ({
  name: css`
    font-size: ${token.fontSizeLG}px;
    font-weight: ${token.fontWeightStrong};

    &.${prefixCls}-typography {
      margin-bottom: 0;
    }
  `,
  card: css`
    width: 320px;
    height: 139px;
  `,
}));
