import { Trans } from '@lingui/macro';
import { useQuery } from '@tanstack/react-query';
import { Link } from '@tanstack/react-router';
import { Button, Flex, Typography } from 'antd';
import axios from 'axios';
import { Swiper, SwiperSlide } from 'swiper/react';

import { useStyles } from './styles.ts';

import { AnnounceItem, announceQueries } from '@/entities/announce';

import {
  AxiosErrorBoundary,
  NoContent,
  WidgetSkeleton,
} from '@/shared/components';

import 'swiper/css';

const widgetTitle = (
  <Typography.Title level={4}>
    <Trans>Объявления</Trans>
  </Typography.Title>
);

const widgetLink = (
  <Link to={'/announces'}>
    <Button size='large' block>
      <Trans>Ко всем объявлениям</Trans>
    </Button>
  </Link>
);

export const Announce = () => {
  const { styles } = useStyles();

  const { data, isPending, error } = useQuery(announceQueries.last());

  if (!isPending && data && data.data.length > 0) {
    return (
      <Flex vertical gap={24}>
        {widgetTitle}

        <Swiper
          grabCursor
          spaceBetween={8}
          slidesPerView='auto'
          className={styles.swiper}
        >
          {data.data.map((announce) => (
            <SwiperSlide key={announce.id} className={styles.swiperSlide}>
              <AnnounceItem announce={announce} />
            </SwiperSlide>
          ))}
        </Swiper>

        {widgetLink}
      </Flex>
    );
  }

  if (error && axios.isAxiosError(error)) {
    return (
      <Flex vertical gap={24}>
        {widgetTitle}
        <AxiosErrorBoundary error={error} />
      </Flex>
    );
  }

  if (!isPending && (!data || data.data.length === 0)) {
    return (
      <Flex vertical gap={24}>
        {widgetTitle}
        <NoContent title='Объявлений нет' />

        {widgetLink}
      </Flex>
    );
  }

  return <WidgetSkeleton />;
};
