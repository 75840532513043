// import * as Sentry from '@sentry/react';
import { createRouter, RouterProvider } from '@tanstack/react-router';
import { Spin } from 'antd';
import axios from 'axios';

import { withProviders } from './providers';
import { queryClient } from './providers/withQueryClientProvider.tsx';
import { routeTree } from './route-tree/routeTree.gen.ts';

import { useProfileStore } from '@/entities/profile';

import { ErrorBoundary, NotFound } from '@/shared/components';

import { MyRouterContext } from '@/routes/__root.tsx';

import './styles/index.scss';

const router = createRouter({
  routeTree,
  context: {
    queryClient: undefined!,
    profileStore: undefined!,
  },
  defaultNotFoundComponent: () => <NotFound />,
  defaultPendingComponent: () => <Spin />,
  defaultErrorComponent: (error) => <ErrorBoundary error={error} />,
  defaultOnCatch: (error) => {
    if (axios.isAxiosError(error)) {
      if (error.status === 401) {
        // logic to unlogin user
      }
    }
  },
});

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

// Sentry.init({
//   dsn: import.meta.env.VITE_SENTRY_DSN,
//   integrations: [
//     Sentry.browserTracingIntegration(),
//     Sentry.replayIntegration(),
//     Sentry.tanstackRouterBrowserTracingIntegration(router),
//     Sentry.globalHandlersIntegration({
//       onerror: false,
//       onunhandledrejection: false,
//     }),
//   ],
//   // Tracing
//   tracesSampleRate: 0.2, //  Capture 20% of the transactions
//   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//   tracePropagationTargets: ['localhost', /^https:\/\/v2\.instudy\.online/],
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

const MyApp = () => {
  const context: MyRouterContext = {
    queryClient,
    profileStore: useProfileStore(),
  };

  return <RouterProvider router={router} context={context} />;
};
const AppWithProviders = withProviders(MyApp);

export default AppWithProviders;
