export const ssoRedirect = () => {
  const envRecords = import.meta.env;

  const clientId = envRecords.VITE_SSO_CLIENT_ID;
  const redirectURI = envRecords.VITE_BASE_URI;
  const ssoURI = envRecords.VITE_SSO_URI + '/oauth/authorize';
  const responseType = 'code';
  const scope = '';

  window.location.replace(
    ssoURI +
      `?client_id=${clientId}` +
      `&redirect_uri=${redirectURI}` +
      `&response_type=${responseType}` +
      `&scope=${scope}`,
  );
};
