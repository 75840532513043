import { createFileRoute } from '@tanstack/react-router';
import { Layout } from 'antd';

export const Route = createFileRoute('/education')({
  component: RouteComponent,
  loader: () => {
    console.log('education');
  },
});

function RouteComponent() {
  return (
    <Layout.Content style={{ overflowY: 'auto' }}>
      <div style={{ height: 2000 }}>education</div>
    </Layout.Content>
  );
}
