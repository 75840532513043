import { i18n } from '@lingui/core';

import { ELanguages } from '@/shared/config';

export const changeLocale = async (locale: ELanguages) => {
  const { messages } = await import(`../locales/${locale}/messages.po`);

  i18n.load(locale, messages);
  i18n.activate(locale);
};
