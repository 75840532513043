import { Link } from '@tanstack/react-router';
import { Card, Flex, Typography } from 'antd';
import dayjs from 'dayjs';
import { memo } from 'react';

import { useStyles } from './styles.ts';

import { TAnnounceItemDTO } from '@/shared/api';

type TAnnounceItemProps = {
  announce: TAnnounceItemDTO;
};

export const AnnounceItem = memo(({ announce }: TAnnounceItemProps) => {
  const { styles, cx } = useStyles();

  return (
    <Link
      to={'/announces/$announceId'}
      params={{ announceId: String(announce.id) }}
    >
      <Card className={cx('card-shadowed', styles.card)} size='small'>
        <Flex vertical gap={8}>
          <Typography.Text type='secondary'>
            от {dayjs(announce.datetime, 'DD.MM.YYYY').format('D MMMM YYYY')}
          </Typography.Text>
          <Typography.Paragraph className={styles.name} ellipsis={{ rows: 3 }}>
            {announce.title}
          </Typography.Paragraph>
        </Flex>
      </Card>
    </Link>
  );
});
