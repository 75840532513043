import { Trans } from '@lingui/macro';
import { useQuery } from '@tanstack/react-query';
import { Avatar, Button, Card, Flex, Typography } from 'antd';
import axios from 'axios';
import { v4 } from 'uuid';

import { UserOutlined } from '@ant-design/icons';

import { useStyles } from './styles.ts';

import { getCuratorLines } from '../lib/getCuratorLines.tsx';
import { getCuratorName } from '../lib/getCuratorName.tsx';

import { curatorQueries } from '@/entities/curator';

import {
  AxiosErrorBoundary,
  NoContent,
  WidgetSkeleton,
} from '@/shared/components';

const widgetTitle = (
  <Typography.Title level={4}>
    <Trans>Ваш куратор</Trans>
  </Typography.Title>
);

const widgetLink = (
  // <Link to={'/announces'}>
  <Button size='large' block>
    <Trans>Материалы куратора</Trans>
  </Button>
  // </Link>
);

export const Curator = () => {
  const { styles } = useStyles();

  const { data, isPending, error } = useQuery(curatorQueries.personal());

  if (!isPending && data && Object.keys(data).length > 0) {
    return (
      <Flex vertical gap={24}>
        {widgetTitle}

        <Card size='small' className='card-shadowed'>
          <Flex vertical gap={12}>
            <Card.Meta
              title={getCuratorName(data.name)}
              avatar={
                <Avatar
                  size={48}
                  shape='square'
                  src={data.avatar ?? undefined}
                  icon={data.avatar ? undefined : <UserOutlined />}
                />
              }
            />

            {getCuratorLines(data, styles.icon).map((line) => {
              if (line.label) {
                return (
                  <Flex align='center' gap={8} key={v4()}>
                    {line.icon}
                    <Typography.Text type='secondary'>
                      {line.label}
                    </Typography.Text>
                  </Flex>
                );
              }
            })}
          </Flex>
        </Card>

        {widgetLink}
      </Flex>
    );
  }

  if (error && axios.isAxiosError(error)) {
    return (
      <Flex vertical gap={24}>
        {widgetTitle}
        <AxiosErrorBoundary error={error} />
      </Flex>
    );
  }

  if (!isPending && (!data || Object.keys(data).length === 0)) {
    return (
      <Flex vertical gap={24}>
        {widgetTitle}

        <NoContent
          title='Куратор отсутствует'
          description='Вы не привязаны ни к какому куратору'
        />
      </Flex>
    );
  }

  return <WidgetSkeleton />;
};
