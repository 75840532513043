import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import { ReactNode, useEffect } from 'react';

import { useProfileStore } from '@/entities/profile';

import { ELanguages } from '@/shared/config';
import { changeLocale } from '@/shared/lib';

changeLocale(ELanguages.RU);

export const withI18nProvider = (Component: () => ReactNode) => () => {
  const profile = useProfileStore.use.profile();

  useEffect(() => {
    changeLocale(profile.user.lang);
  }, [profile.user.lang]);

  return (
    // eslint-disable-next-line
    // @ts-ignore
    <I18nProvider i18n={i18n}>
      <Component />
    </I18nProvider>
  );
};
