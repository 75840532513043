import { Flex, Layout } from 'antd';

import { WidgetSkeleton } from '@/shared/components';

export const MainPending = () => {
  return (
    <Layout.Content className='content'>
      <Flex gap={48} vertical>
        <WidgetSkeleton />
        <WidgetSkeleton />
        <WidgetSkeleton />
        <WidgetSkeleton />
        <WidgetSkeleton />
        <WidgetSkeleton />
      </Flex>
    </Layout.Content>
  );
};
