import { useStyles } from './styles.ts';

export const Loader = () => {
  const { styles } = useStyles();

  return (
    <div className={styles.container}>
      <span className={styles.loader}></span>
    </div>
  );
};
