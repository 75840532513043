import { Button, Collapse, Flex, Modal, Result, Typography } from 'antd';
import { AxiosError } from 'axios';
import { useState } from 'react';
import { v4 } from 'uuid';

import { useStyles } from './styles.ts';

type TAxiosErrorBoundaryProps = {
  error: AxiosError;
};
type TBackendTrace = {
  class: string;
  file: string;
  function: string;
  line: number;
  type: string;
};
type T500BackendError = {
  exception: string;
  file: string;
  line: number;
  message: string;
  trace: TBackendTrace[];
};
type TDefaultBackendError = Record<string, string[]>;

const EllipsisMiddle = ({
  suffixCount,
  children,
}: {
  suffixCount: number;
  children: string;
}) => {
  const start = children.slice(0, children.length - suffixCount);
  const suffix = children.slice(-suffixCount).trim();

  return (
    <Typography.Text style={{ maxWidth: '100%' }} ellipsis={{ suffix }}>
      {start}
    </Typography.Text>
  );
};
const ErrorContentRow = ({ label, text }: { label: string; text: string }) => {
  const { styles } = useStyles();

  return (
    <Typography.Paragraph className={styles.row}>
      <Typography.Text strong>{label}: </Typography.Text>
      <Typography.Text>{text}</Typography.Text>
    </Typography.Paragraph>
  );
};
const ErrorTrace = ({ trace }: { trace: TBackendTrace }) => {
  return (
    <Flex vertical>
      <ErrorContentRow label='Класс' text={trace.file} />
      <ErrorContentRow label='Файл' text={trace.class} />
      <ErrorContentRow label='Функция' text={trace.function} />
      <ErrorContentRow label='Строка' text={String(trace.line)} />
      <ErrorContentRow label='Тип' text={trace.type} />
    </Flex>
  );
};

const is500BackendError = (error: unknown): error is T500BackendError => {
  return (
    !!error &&
    typeof error === 'object' &&
    'exception' in error &&
    'file' in error &&
    'message' in error
  );
};

export const AxiosErrorBoundary = ({ error }: TAxiosErrorBoundaryProps) => {
  const { styles } = useStyles();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const backendError = error.response?.data as unknown as
    | T500BackendError
    | TDefaultBackendError;

  const backendTraceCollapseItems = backendError.trace?.map((trace) => {
    return {
      key: v4(),
      label: <EllipsisMiddle suffixCount={37}>{trace.file}</EllipsisMiddle>,
      children: <ErrorTrace trace={trace} />,
    };
  });

  return (
    <>
      <Result
        status={500}
        title='Ошибка сервера'
        className={styles.resultContainer}
        subTitle={
          <Flex vertical>
            <Typography.Text type='secondary'>
              Статус: {error.status}
            </Typography.Text>
            <Typography.Text type='secondary'>
              Код: {error.code}
            </Typography.Text>
          </Flex>
        }
        extra={
          <Button block onClick={() => setIsModalOpen(true)} size='large'>
            Содержание ошибки
          </Button>
        }
      />

      <Modal
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        title={
          <Typography.Title className={styles.title} level={3}>
            Ошибка сервера
          </Typography.Title>
        }
        destroyOnClose
        footer={null}
      >
        {is500BackendError(backendError) ? (
          <Flex vertical className={styles.backendError}>
            <ErrorContentRow label='Исключение' text={backendError.exception} />
            <ErrorContentRow label='Файл' text={backendError.file} />
            <ErrorContentRow label='Строка' text={String(backendError.line)} />
            <ErrorContentRow label='Сообщение' text={backendError.message} />

            <Flex vertical gap={8}>
              <Collapse items={backendTraceCollapseItems} />
            </Flex>
          </Flex>
        ) : (
          <Flex vertical className={styles.backendError}>
            <ErrorContentRow label='Сообщение' text={error.message} />

            {Object.keys(backendError)?.map((error) => (
              <ErrorContentRow
                key={v4()}
                label={error}
                text={backendError[error].join('; ')}
              />
            ))}
          </Flex>
        )}
      </Modal>
    </>
  );
};
