import { queryOptions } from '@tanstack/react-query';

import { fetchClosestFinance } from '@/shared/api';

export const financeQueries = {
  closest: () =>
    queryOptions({
      queryKey: ['finance', 'closest'],
      queryFn: fetchClosestFinance,
    }),
};
