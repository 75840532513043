import {
  MailFilled,
  PhoneFilled,
  SendOutlined,
  WhatsAppOutlined,
} from '@ant-design/icons';

import { TCuratorDTO } from '@/shared/api';

export const getCuratorLines = (curator: TCuratorDTO, iconStyle: string) => {
  return [
    {
      icon: <MailFilled className={iconStyle} />,
      label: curator.email,
    },
    {
      icon: <SendOutlined className={iconStyle} />,
      label: curator.skype,
    },
    {
      icon: <PhoneFilled className={iconStyle} />,
      label: `${curator.phone} (доб. ${curator.addphone})`,
    },
    {
      icon: <WhatsAppOutlined className={iconStyle} />,
      label: curator.whatsapp,
    },
  ];
};
