import Cookies from 'js-cookie';
import { useEffect } from 'react';

import { useProfileStore } from '../model/profileStore.ts';

import { fetchProfile, fetchSSO } from '@/shared/api';
import { EStorageKeys } from '@/shared/config';

export const useAuth = () => {
  const updateToken = useProfileStore.use.updateToken();
  const updateProfile = useProfileStore.use.updateProfile();
  const updateIsLoading = useProfileStore.use.updateIsLoading();

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);

    const code = searchParams.get('code');
    const token = Cookies.get(EStorageKeys.AUTH_TOKEN);

    (async function () {
      try {
        if (code) {
          const data = await fetchSSO(code);

          updateProfile(data.profile);
          updateToken(data.token);

          searchParams.delete('code');
          window.location.search = searchParams.toString();
        } else if (token) {
          const data = await fetchProfile();

          updateProfile(data.profile);
        }
      } catch (error) {
        updateToken(null);
        console.error('Authentication failed:', error);
      } finally {
        updateIsLoading(false);
      }
    })();
  }, []);
};
