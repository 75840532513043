import { Link, LinkProps } from '@tanstack/react-router';
import { Button, Flex, Result } from 'antd';

import { useStyles } from './styles.ts';

type TNotFoundProps = {
  title?: string;
  subTitle?: string;
  backLink?: LinkProps['to'];
};

export const NotFound = ({ title, subTitle, backLink }: TNotFoundProps) => {
  const { styles } = useStyles();

  const extra = (
    <Link to={backLink ?? '/'}>
      <Button type='primary'>Вернуться</Button>
    </Link>
  );

  return (
    <Flex vertical align='center' justify='center' className={styles.container}>
      <Result
        status='404'
        title={title ?? '404'}
        subTitle={subTitle ?? 'Запрашиваемая вами страница не найдена.'}
        extra={extra}
      />
    </Flex>
  );
};
