import { createStyles } from 'antd-style';

export const useStyles = createStyles(({ css, token }) => ({
  menu: css`
    display: flex;
    justify-content: space-between;
    width: 100%;
  `,
  moduleDescription: css`
    color: inherit;
    font-size: ${token.fontSizeSM}px;
    margin-inline-start: 0 !important;
  `,
  moduleIcon: css`
    font-size: ${token.fontSizeLG}px;
  `,
  label: css`
    text-align: center;
  `,
}));
