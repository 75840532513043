import { Layout } from 'antd';
import { ReactNode } from 'react';

import { useStyles } from './styles.ts';

type FooterProps = {
  menu: ReactNode;
};

export const Footer = ({ menu }: FooterProps) => {
  const { styles } = useStyles();

  return <Layout.Footer className={styles.footer}>{menu}</Layout.Footer>;
};
