import axios, { AxiosError } from 'axios';
import Cookies from 'js-cookie';

import { EStorageKeys } from '@/shared/config';

const axiosDefaults = {
  baseURL: import.meta.env.VITE_BACKEND_API_ENDPOINT + '/api/',
  headers: {
    'Content-Type': 'application/json',
  },
};
const http = axios.create(axiosDefaults);

http.interceptors.request.use(
  function (config) {
    const token = Cookies.get(EStorageKeys.AUTH_TOKEN);

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  function (error: AxiosError): Promise<AxiosError> {
    return Promise.reject(error);
  },
);
http.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error: AxiosError): Promise<AxiosError> {
    return Promise.reject(error);
  },
);

export { http };
