import { createStyles } from 'antd-style';

export const useStyles = createStyles(({ css, token }) => ({
  text: css`
    font-size: ${token.fontSizeLG}px;
    line-height: ${token.lineHeightLG}px;
  `,
  registryButton: css`
    color: ${token.colorPrimary};

    &:hover {
      color: ${token.colorPrimaryHover} !important;
    }

    &:active {
      color: ${token.colorPrimaryActive} !important;
    }
  `,
  ssoButton: css`
    display: flex;
    align-items: center;
  `,
}));
