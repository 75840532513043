/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './../../routes/__root';
import { Route as EducationImport } from './../../routes/education';
import { Route as IndexImport } from './../../routes/index';
import { Route as ScheduleIndexImport } from './../../routes/schedule/index';
import { Route as AnnouncesIndexImport } from './../../routes/announces/index';
import { Route as ScheduleLessonIdImport } from './../../routes/schedule/$lessonId_';
import { Route as AnnouncesAnnounceIdImport } from './../../routes/announces/$announceId_';

// Create/Update Routes

const EducationRoute = EducationImport.update({
  id: '/education',
  path: '/education',
  getParentRoute: () => rootRoute,
} as any);

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any);

const ScheduleIndexRoute = ScheduleIndexImport.update({
  id: '/schedule/',
  path: '/schedule/',
  getParentRoute: () => rootRoute,
} as any);

const AnnouncesIndexRoute = AnnouncesIndexImport.update({
  id: '/announces/',
  path: '/announces/',
  getParentRoute: () => rootRoute,
} as any);

const ScheduleLessonIdRoute = ScheduleLessonIdImport.update({
  id: '/schedule/$lessonId_',
  path: '/schedule/$lessonId',
  getParentRoute: () => rootRoute,
} as any);

const AnnouncesAnnounceIdRoute = AnnouncesAnnounceIdImport.update({
  id: '/announces/$announceId_',
  path: '/announces/$announceId',
  getParentRoute: () => rootRoute,
} as any);

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/';
      path: '/';
      fullPath: '/';
      preLoaderRoute: typeof IndexImport;
      parentRoute: typeof rootRoute;
    };
    '/education': {
      id: '/education';
      path: '/education';
      fullPath: '/education';
      preLoaderRoute: typeof EducationImport;
      parentRoute: typeof rootRoute;
    };
    '/announces/$announceId_': {
      id: '/announces/$announceId_';
      path: '/announces/$announceId';
      fullPath: '/announces/$announceId';
      preLoaderRoute: typeof AnnouncesAnnounceIdImport;
      parentRoute: typeof rootRoute;
    };
    '/schedule/$lessonId_': {
      id: '/schedule/$lessonId_';
      path: '/schedule/$lessonId';
      fullPath: '/schedule/$lessonId';
      preLoaderRoute: typeof ScheduleLessonIdImport;
      parentRoute: typeof rootRoute;
    };
    '/announces/': {
      id: '/announces/';
      path: '/announces';
      fullPath: '/announces';
      preLoaderRoute: typeof AnnouncesIndexImport;
      parentRoute: typeof rootRoute;
    };
    '/schedule/': {
      id: '/schedule/';
      path: '/schedule';
      fullPath: '/schedule';
      preLoaderRoute: typeof ScheduleIndexImport;
      parentRoute: typeof rootRoute;
    };
  }
}

// Create and export the route tree

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute;
  '/education': typeof EducationRoute;
  '/announces/$announceId': typeof AnnouncesAnnounceIdRoute;
  '/schedule/$lessonId': typeof ScheduleLessonIdRoute;
  '/announces': typeof AnnouncesIndexRoute;
  '/schedule': typeof ScheduleIndexRoute;
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute;
  '/education': typeof EducationRoute;
  '/announces/$announceId': typeof AnnouncesAnnounceIdRoute;
  '/schedule/$lessonId': typeof ScheduleLessonIdRoute;
  '/announces': typeof AnnouncesIndexRoute;
  '/schedule': typeof ScheduleIndexRoute;
}

export interface FileRoutesById {
  __root__: typeof rootRoute;
  '/': typeof IndexRoute;
  '/education': typeof EducationRoute;
  '/announces/$announceId_': typeof AnnouncesAnnounceIdRoute;
  '/schedule/$lessonId_': typeof ScheduleLessonIdRoute;
  '/announces/': typeof AnnouncesIndexRoute;
  '/schedule/': typeof ScheduleIndexRoute;
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath;
  fullPaths:
    | '/'
    | '/education'
    | '/announces/$announceId'
    | '/schedule/$lessonId'
    | '/announces'
    | '/schedule';
  fileRoutesByTo: FileRoutesByTo;
  to:
    | '/'
    | '/education'
    | '/announces/$announceId'
    | '/schedule/$lessonId'
    | '/announces'
    | '/schedule';
  id:
    | '__root__'
    | '/'
    | '/education'
    | '/announces/$announceId_'
    | '/schedule/$lessonId_'
    | '/announces/'
    | '/schedule/';
  fileRoutesById: FileRoutesById;
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute;
  EducationRoute: typeof EducationRoute;
  AnnouncesAnnounceIdRoute: typeof AnnouncesAnnounceIdRoute;
  ScheduleLessonIdRoute: typeof ScheduleLessonIdRoute;
  AnnouncesIndexRoute: typeof AnnouncesIndexRoute;
  ScheduleIndexRoute: typeof ScheduleIndexRoute;
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  EducationRoute: EducationRoute,
  AnnouncesAnnounceIdRoute: AnnouncesAnnounceIdRoute,
  ScheduleLessonIdRoute: ScheduleLessonIdRoute,
  AnnouncesIndexRoute: AnnouncesIndexRoute,
  ScheduleIndexRoute: ScheduleIndexRoute,
};

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>();

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/education",
        "/announces/$announceId_",
        "/schedule/$lessonId_",
        "/announces/",
        "/schedule/"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/education": {
      "filePath": "education.tsx"
    },
    "/announces/$announceId_": {
      "filePath": "announces/$announceId_.tsx"
    },
    "/schedule/$lessonId_": {
      "filePath": "schedule/$lessonId_.tsx"
    },
    "/announces/": {
      "filePath": "announces/index.tsx"
    },
    "/schedule/": {
      "filePath": "schedule/index.tsx"
    }
  }
}
ROUTE_MANIFEST_END */
