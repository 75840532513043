import { Flex, Typography } from 'antd';
import { ReactNode } from 'react';

import { useStyles } from '@/pages/root/ui/styles.ts';

type TMenuLabelProps = {
  icon: ReactNode;
  description: string;
};

export const MenuLabelContent = ({ icon, description }: TMenuLabelProps) => {
  const { styles } = useStyles();

  return (
    <Flex vertical align='center' className={styles.label}>
      {icon}
      <Typography.Text className={styles.moduleDescription}>
        {description}
      </Typography.Text>
    </Flex>
  );
};
