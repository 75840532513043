import { createSelectorFunctions } from 'auto-zustand-selectors-hook';
import Cookies from 'js-cookie';
import { create } from 'zustand';

import { TProfileDTO } from '@/shared/api';

type TState = {
  isLoading: boolean;
  profile: TProfileDTO;
};

type TAction = {
  updateIsLoading: (isLoading: boolean) => void;
  updateProfile: (profile: TProfileDTO) => void;
  hasAccessToModule: (moduleName: string) => boolean;
  updateToken: (token: string | null) => void;
};

export type TProfileStore = TState & TAction;

const useProfileStoreBase = create<TProfileStore>()((set, get) => ({
  profile: null as unknown as TProfileDTO,
  isLoading: true,
  // actions
  updateProfile: (profile) => set(() => ({ profile })),
  updateIsLoading: (isLoading) => set(() => ({ isLoading })),
  // another
  updateToken: (token) => {
    if (token) Cookies.set('auth_token', token);
    else Cookies.remove('auth_token');
  },
  hasAccessToModule: (moduleName) => {
    const profile = get().profile;

    if (!profile) return false;
    return profile.modules.some((module) => module.name === moduleName);
  },
}));

export const useProfileStore = createSelectorFunctions(useProfileStoreBase);
