import { Card, Divider, Flex, Typography } from 'antd';
import { ReactNode } from 'react';
import { v4 } from 'uuid';

import { useStyles } from './styles.ts';

type TMultilineCardProps = {
  title: string;
  lines: {
    label: ReactNode;
    value: ReactNode;
  }[];
};

export const MultilineCard = ({ title, lines }: TMultilineCardProps) => {
  const { styles } = useStyles();

  return (
    <Card className={'card-shadowed'} size='small'>
      <Flex vertical gap={12}>
        <Typography.Text strong>{title}</Typography.Text>

        {lines.map((line) => (
          <Flex align='flex-end' gap={8} justify='space-between' key={v4()}>
            {typeof line.label === 'string' ? (
              <Typography.Text>{line.label}</Typography.Text>
            ) : (
              line.label
            )}

            <Divider dashed className={styles.divider} />

            {typeof line.value === 'string' ? (
              <Typography.Text>{line.value}</Typography.Text>
            ) : (
              line.value
            )}
          </Flex>
        ))}
      </Flex>
    </Card>
  );
};
