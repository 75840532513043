import { Trans } from '@lingui/macro';
import { useQuery } from '@tanstack/react-query';
import { Button, Flex, Typography } from 'antd';
import axios from 'axios';

import { useStyles } from './styles.ts';

import { getFinanceLines } from '../lib/getFinanceLines.tsx';

import { financeQueries } from '@/entities/finance';

import {
  AxiosErrorBoundary,
  MultilineCard,
  NoContent,
  WidgetSkeleton,
} from '@/shared/components';

const widgetTitle = (
  <Typography.Title level={4}>
    <Trans>Финансы</Trans>
  </Typography.Title>
);

const widgetLink = (
  // <Link to={'/announces'}>
  <Button size='large' block>
    <Trans>Оплатить обучение</Trans>
  </Button>
  // </Link>
);

export const Finance = () => {
  const { styles } = useStyles();

  const { data, isPending, error } = useQuery(financeQueries.closest());

  if (!isPending && data?.nextPayment) {
    return (
      <Flex vertical gap={24}>
        {widgetTitle}

        <MultilineCard
          title='Ближайшая дата платежа'
          lines={getFinanceLines(data, styles)}
        />

        {widgetLink}
      </Flex>
    );
  }

  if (error && axios.isAxiosError(error)) {
    return (
      <Flex vertical gap={24}>
        {widgetTitle}
        <AxiosErrorBoundary error={error} />
      </Flex>
    );
  }

  if (!isPending && !data?.nextPayment) {
    return (
      <Flex vertical gap={24}>
        {widgetTitle}

        <NoContent
          title='Ближайшие платежи отсутствуют'
          description='Обучение полностью оплачено'
        />
      </Flex>
    );
  }

  return <WidgetSkeleton />;
};
