import { Button, Flex, Typography } from 'antd';
import { useResponsive } from 'antd-style';

import { useStyles } from './styles.ts';

import IcSSoLogo from '@/shared/assets/icons/ic_sso-logo-light.svg?react';
import { ssoRedirect } from '@/shared/lib';

export const SsoForm = () => {
  const { Text } = Typography;
  const { styles } = useStyles();
  const { xs } = useResponsive();

  return (
    <Flex vertical gap={24}>
      <Flex vertical gap={8} align='center'>
        <Button type='primary' size='large' block onClick={ssoRedirect}>
          Войти через InStudyID
        </Button>

        <Flex gap={4} align='center'>
          <Text type='secondary' className={styles.text}>
            Нет аккаунта?
          </Text>

          <Button
            type='link'
            size='large'
            className={styles.registryButton}
            href={import.meta.env.VITE_SSO_URI + '/auth/register'}
          >
            Регистрация
          </Button>
        </Flex>
      </Flex>

      <Flex gap={4} vertical={xs} align='center' justify='center'>
        <div>
          <Text type='secondary' className={styles.text}>
            Выйти из InStudyID можно в
          </Text>
        </div>

        <a href={import.meta.env.VITE_SSO_URI + '/home'}>
          <Button
            icon={<IcSSoLogo />}
            className={styles.ssoButton}
            type='text'
            size='large'
          >
            Аккаунте SSO
          </Button>
        </a>
      </Flex>
    </Flex>
  );
};
