import compose from 'compose-function';

import { withAntConfigProvider } from './withAntConfigProvider';
import { withAntStyleProvider } from './withAntStyleProvider.tsx';
import { withAppProvider } from './withAppProvider.tsx';
import { withAuthProvider } from './withAuthProvider.tsx';
import { withI18nProvider } from './withI18nProvider.tsx';
import { withQueryClientProvider } from './withQueryClientProvider.tsx';

export const withProviders = compose(
  withAntConfigProvider,
  withAuthProvider,
  withI18nProvider,
  withAntStyleProvider,
  withAppProvider,
  withQueryClientProvider,
);
