import { createStyles } from 'antd-style';

export const useStyles = createStyles(({ css, token }) => ({
  warnText: css`
    color: ${token.colorWarning};
  `,
  successText: css`
    color: ${token.colorSuccess};
  `,
  errorText: css`
    color: ${token.colorError};
  `,
}));
export type FinanceStyles = ReturnType<typeof useStyles>['styles'];
