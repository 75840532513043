import { queryOptions } from '@tanstack/react-query';

import { fetchCurator } from '@/shared/api';

export const curatorQueries = {
  personal: () =>
    queryOptions({
      queryKey: ['curator', 'personal'],
      queryFn: fetchCurator,
    }),
};
