import { Trans } from '@lingui/macro';
import { useQuery } from '@tanstack/react-query';
import { Link } from '@tanstack/react-router';
import { Button, Flex, Typography } from 'antd';
import axios from 'axios';

import { ScheduleLesson, scheduleQueries } from '@/entities/schedule';

import {
  AxiosErrorBoundary,
  NoContent,
  WidgetSkeleton,
} from '@/shared/components';
import { getLessonsWord } from '@/shared/lib';

const widgetTitle = (
  <Typography.Title level={4}>
    <Trans>Расписание на сегодня</Trans>
  </Typography.Title>
);

const widgetLink = (
  <Link to={'/schedule'}>
    <Button size='large' block>
      <Trans>Посмотреть все расписание</Trans>
    </Button>
  </Link>
);

export const Schedule = () => {
  const { data, isPending, error } = useQuery(scheduleQueries.today());

  if (!isPending && data && data.length > 0) {
    return (
      <Flex vertical gap={24}>
        <Flex align='center' justify='space-between' gap={12}>
          {widgetTitle}
          <Typography.Title level={4} type='secondary'>
            {data.length} {getLessonsWord(data.length)}
          </Typography.Title>
        </Flex>

        <Flex vertical gap={8}>
          {data.map((lesson) => (
            <ScheduleLesson lesson={lesson} isLink key={lesson.id} />
          ))}
        </Flex>

        {widgetLink}
      </Flex>
    );
  }

  if (error && axios.isAxiosError(error)) {
    return (
      <Flex vertical gap={24}>
        {widgetTitle}
        <AxiosErrorBoundary error={error} />
      </Flex>
    );
  }

  if (!isPending && (!data || data.length === 0)) {
    return (
      <Flex vertical gap={24}>
        {widgetTitle}
        <NoContent
          title='Занятий нет'
          description='А пока можете посмотреть расписание на другие дни'
        />

        {widgetLink}
      </Flex>
    );
  }

  return <WidgetSkeleton />;
};
