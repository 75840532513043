import { Avatar, Badge, Button, Flex, Layout, Typography } from 'antd';

import { BellOutlined, UserOutlined } from '@ant-design/icons';

import { useStyles } from './styles.ts';

import { TProfileDTO } from '@/shared/api';

type HeaderProps = {
  profile: TProfileDTO;
};

export const Header = ({ profile }: HeaderProps) => {
  const { styles, theme } = useStyles();

  return (
    <Layout.Header className={styles.header}>
      <Flex align='center' justify='space-between' className={styles.container}>
        <Flex align='center' gap={12}>
          <Avatar
            shape='circle'
            size={24}
            src={
              profile.photoUrl &&
              'https://storage.yandexcloud.net/sso-dev/avatars/1732191947.jpg'
            }
            icon={!profile.photoUrl && <UserOutlined />}
          />
          <Typography.Text>{profile.firstName}</Typography.Text>
        </Flex>

        <Button
          type='text'
          size='large'
          shape='circle'
          icon={
            <Badge offset={[-3, 3]} dot color={theme.colorError}>
              <BellOutlined />
            </Badge>
          }
        />
      </Flex>
    </Layout.Header>
  );
};
