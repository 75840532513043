import { Link } from '@tanstack/react-router';
import { ReactNode } from 'react';

import { MenuLabelContent } from './MenuLabelContent.tsx';

type TMenuLabelProps = {
  icon: ReactNode;
  description: string;
  disabled?: boolean;
};
type TMenuLabelLinkProps = TMenuLabelProps & { link: string };
type TMenuLabelButtonProps = TMenuLabelProps & { onClick: () => void };

const MenuLabelLink = ({
  icon,
  description,
  link,
  disabled,
}: TMenuLabelLinkProps) => {
  return (
    <Link to={link} disabled={disabled}>
      <MenuLabelContent icon={icon} description={description} />
    </Link>
  );
};

const MenuLabelButton = ({
  icon,
  description,
  onClick,
  disabled,
}: TMenuLabelButtonProps) => {
  return (
    <div role='button' onClick={!disabled ? onClick : undefined}>
      <MenuLabelContent icon={icon} description={description} />
    </div>
  );
};

export { MenuLabelButton, MenuLabelLink };
