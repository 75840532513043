import { Flex, Typography } from 'antd';
import dayjs from 'dayjs';
import { ReactNode } from 'react';

import { FinanceStyles } from '../ui/styles.ts';

import { TClosestFinanceDTO } from '@/shared/api';
import { getDaysWord } from '@/shared/lib';

export const getFinanceLines = (
  finance: TClosestFinanceDTO,
  styles: FinanceStyles,
) => {
  const lines: {
    label: ReactNode;
    value: ReactNode;
  }[] = [];

  const { daysUntilPayment, date, sum, warning } = finance.nextPayment!;
  const formattedDate = dayjs(date, 'DD.MM.YYYY').format('D MMMM YYYY');

  if (warning) {
    const label = (
      <Flex gap={8}>
        <Typography.Text className={styles.warnText}>
          {formattedDate}
        </Typography.Text>
        <Typography.Text type='secondary'>
          ({daysUntilPayment} {getDaysWord(daysUntilPayment)})
        </Typography.Text>
      </Flex>
    );
    const value = (
      <Typography.Text className={styles.warnText}>
        {sum} &#8381;
      </Typography.Text>
    );

    lines.push({ label, value });
  } else {
    lines.push({
      label: formattedDate,
      value: `${sum} ₽`,
    });
  }

  if (finance.debt) {
    const label = (
      <Typography.Text className={styles.errorText}>
        Текущий долг
      </Typography.Text>
    );
    const value = (
      <Typography.Text className={styles.errorText}>
        {finance.debt} &#8381;
      </Typography.Text>
    );

    lines.push({ label, value });
  } else {
    const label = 'Текущий долг';
    const value = (
      <Typography.Text className={styles.successText}>
        0 &#8381;
      </Typography.Text>
    );

    lines.push({ label, value });
  }

  return lines;
};
