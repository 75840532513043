export const enum ELanguages {
  RU = 'ru',
  EN = 'en',
}

export const enum EStorageKeys {
  AUTH_TOKEN = 'auth_token',
  THEME = 'theme',
}

export const enum EThemes {
  LIGHT = 'light',
  DARK = 'dark',
}
