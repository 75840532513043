import { ThemeConfig } from 'antd';
import { createSelectorFunctions } from 'auto-zustand-selectors-hook';
import Cookies from 'js-cookie';
import { create } from 'zustand';

import { appTheme } from '../config/appTheme.ts';
import { updateFavicon } from '../lib/updateFavicon.ts';
import { updateMeta } from '../lib/updateMeta.ts';

import { EStorageKeys, EThemes } from '@/shared/config';

type TState = {
  theme: EThemes;
};

type TAction = {
  updateTheme: (theme: EThemes) => void;
  getAntdThemeConfig: () => ThemeConfig;
};

export type TThemeStore = TState & TAction;

const useThemeStoreBase = create<TThemeStore>()((set, get) => ({
  theme: EThemes.LIGHT, // getInitialTheme(),
  // actions
  updateTheme: (theme) => {
    set(() => ({ theme }));
    updateFavicon(theme);
    updateMeta(theme);
    Cookies.set(EStorageKeys.THEME, theme);
  },
  // another
  getAntdThemeConfig: () => {
    return appTheme(get().theme);
  },
}));

export const useThemeStore = createSelectorFunctions(useThemeStoreBase);
