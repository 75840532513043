import { createStyles, keyframes } from 'antd-style';

const rotation = keyframes`
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
`;

export const useStyles = createStyles(({ css }) => ({
  container: css`
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
  `,
  loader: css`
    border: 8px dotted #e7405a;
    animation: ${rotation} 2.5s linear infinite;
    border-radius: 50%;
    box-sizing: border-box;
    display: inline-block;
    height: 90px;
    position: relative;
    width: 90px;
  `,
}));
