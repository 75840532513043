import { createStyles } from 'antd-style';

export const useStyles = createStyles(({ css }) => ({
  divider: css`
    height: 2px;
    margin: 0;
    flex: 1 1 auto;
    min-width: auto;
    width: auto;
  `,
}));
