import { queryOptions } from '@tanstack/react-query';

import { fetchClosestTest } from '@/shared/api';

export const testQueries = {
  closest: () =>
    queryOptions({
      queryKey: ['test', 'closest'],
      queryFn: fetchClosestTest,
    }),
};
