import { http } from '@/shared/api';

export type TAnnounceItemDTO = {
  id: number;
  title: string;
  datetime: string;
};
export type TAnnounceDTO = TAnnounceItemDTO & {
  text: string;
};

export const fetchLastAnnounces = async () => {
  const response = await http.get<TAnnounceItemDTO[]>('widgets/announce');
  return response;
};
export const fetchAnnounce = async (id: string) => {
  const response = await http.get<TAnnounceDTO>(`announces/${id}`);
  return response.data;
};
