import { http } from '@/shared/api';

export type TClosestExamDTO = {
  start: string;
  finish: string;
  daysUntilStart: number;
};

export const fetchClosestExam = async () => {
  const response = await http.get<TClosestExamDTO>('widgets/exam');
  return response.data;
};
