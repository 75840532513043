export const getCuratorName = (name: string) => {
  const nameParts = name.split(' ');
  if (nameParts.length !== 3) {
    return name;
  }

  const [lastName, firstName, patronymic] = nameParts;

  return (
    <>
      {lastName} {firstName} <br />
      {patronymic}
    </>
  );
};
