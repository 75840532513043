import { Flex, Typography } from 'antd';

import { SsoForm } from './sso/SsoForm.tsx';
import { useStyles } from './styles.ts';

import IcLogoShort from '@/shared/assets/icons/ic_logo-short.svg?react';

export const AuthPage = () => {
  const { Title, Text } = Typography;

  const { styles } = useStyles();

  return (
    <div className={styles.wrapper}>
      <Flex vertical gap={24} className={styles.card}>
        <Flex vertical align='center' gap={24}>
          <IcLogoShort className={styles.icon} />

          <Flex vertical gap={4} align='center'>
            <Title level={2} className={styles.title}>
              Авторизация
            </Title>

            <Text type='secondary'>Выберите способ входа в аккаунт</Text>
          </Flex>
        </Flex>

        <SsoForm />
      </Flex>
    </div>
  );
};
