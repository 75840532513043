import { queryOptions } from '@tanstack/react-query';

import { fetchClosestExam } from '@/shared/api';

export const examQueries = {
  closest: () =>
    queryOptions({
      queryKey: ['exam', 'closest'],
      queryFn: fetchClosestExam,
    }),
};
