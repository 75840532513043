import { http } from '@/shared/api';

export type TClosestFinanceDTO = {
  debt: number;
  nextPayment: {
    semestr: number;
    date: string;
    sum: number;
    daysUntilPayment: number;
    warning: boolean;
  } | null;
};

export const fetchClosestFinance = async () => {
  const response = await http.get<TClosestFinanceDTO>('widgets/finance');
  return response.data;
};
