import { createStyles } from 'antd-style';

export const useStyles = createStyles(({ css, token, responsive }) => ({
  main: css`
    padding: 32px;
    overflow: auto;

    background-color: ${token.colorBgElevated};

    ${responsive.sm} {
      padding: 0;
    }
  `,
  wrapper: css`
    display: flex;
    align-items: center;
    justify-content: center;

    min-height: 100%;

    ${responsive.sm} {
      display: block;
    }
  `,
  card: css`
    width: 100%;
    max-width: 480px;

    padding: 32px;
    border-radius: ${token.borderRadiusLG}px;
    border: 1px solid ${token.colorBorderSecondary};
    background-color: ${token.colorBgContainer};

    ${responsive.sm} {
      max-width: 100%;
      min-height: 100vh;
      justify-content: center;

      border: none;
      border-radius: 0;
      padding: 32px 16px;
    }
  `,
  icon: css`
    ${responsive.sm} {
      height: 64px;
      width: 64px;
    }
  `,
  title: css`
    font-weight: 700;
  `,
}));
