import { Typography } from 'antd';

import { TClosestExamDTO } from '@/shared/api';
import { getDaysWord } from '@/shared/lib';

export const getExamLines = (exam: TClosestExamDTO) => {
  return [
    {
      label: 'Начало',
      value: exam.start,
    },
    {
      label: 'Конец',
      value: exam.finish,
    },
    {
      label: <Typography.Text type='secondary'>Дней до начала</Typography.Text>,
      value: (
        <Typography.Text type='secondary'>
          {exam.daysUntilStart} {getDaysWord(exam.daysUntilStart)}
        </Typography.Text>
      ),
    },
  ];
};
