import { createStyles } from 'antd-style';

export const useStyles = createStyles(({ css, token }) => ({
  discipline: css`
    font-size: ${token.fontSizeLG}px;
  `,
  icon: css`
    margin-right: 8px;
  `,
  card: css`
    position: relative;
  `,
  link: css`
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
  `,
  externalLink: css`
    position: relative;
    z-index: 1;
  `,
  tag: css`
    margin-inline-end: 0;
  `,
}));
