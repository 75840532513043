import { Outlet, ScrollRestoration, useLocation } from '@tanstack/react-router';
import { Layout, Menu } from 'antd';

import { useStyles } from './styles.ts';

import { getMenuItems } from '../config/menuItems.tsx';
import { getSelectedKeys } from '../lib/getSelectedKeys.ts';

import { useProfileStore } from '@/entities/profile';

import { Footer, Header } from '@/shared/layouts';

export const RootPage = () => {
  const { styles } = useStyles();

  const profile = useProfileStore.use.profile();
  const modules = profile.modules;
  const modulesNames = modules.map((module) => module.name);

  const location = useLocation();

  const FooterMenu = () => (
    <Menu
      items={getMenuItems(modules, styles.moduleIcon)}
      mode='inline'
      multiple={false}
      selectedKeys={getSelectedKeys(location.pathname, [...modulesNames, '/'])}
      className={styles.menu}
      inlineIndent={0}
    />
  );

  return (
    <>
      <ScrollRestoration getKey={() => location.pathname} />

      <Layout>
        <Header profile={profile} />
        <Outlet />
        <Footer menu={<FooterMenu />} />
      </Layout>
      {/* {import.meta.env.MODE === "development" && <TanStackRouterDevtools />}*/}
    </>
  );
};
