import { http } from '@/shared/api';

export type TCuratorDTO = {
  id: number;
  email: string;
  avatar: string | null;
  phone: string;
  addphone: string;
  skype: string | null;
  whatsapp: string | null;
  name: string;
};

export const fetchCurator = async () => {
  const response = await http.get<TCuratorDTO>('widgets/curator');
  return response.data;
};
