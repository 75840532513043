import { queryOptions } from '@tanstack/react-query';

import { fetchScheduleLesson, fetchScheduleToday } from '@/shared/api';

export const scheduleQueries = {
  today: () =>
    queryOptions({
      queryKey: ['schedule', 'today'],
      queryFn: fetchScheduleToday,
    }),
  lesson: (lessonId: string) =>
    queryOptions({
      queryKey: ['schedule', 'lesson', lessonId],
      queryFn: () => fetchScheduleLesson(lessonId),
    }),
};
