import { Empty, Flex, Typography } from 'antd';

import { useStyles } from './styles.ts';

type TNoContentProps = {
  title?: string;
  description?: string;
};

export const NoContent = ({ title, description }: TNoContentProps) => {
  const { styles } = useStyles();

  return (
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      rootClassName={styles.empty}
      description={
        <Flex vertical gap={8} align='center'>
          <Typography.Text>{title ?? 'Информация отсутствует'}</Typography.Text>
          <Typography.Text type='secondary'>
            {description ?? 'Попробуйте зайти позже'}
          </Typography.Text>
        </Flex>
      }
    />
  );
};
