import { Trans } from '@lingui/macro';
import { useQuery } from '@tanstack/react-query';
import { Flex, Typography } from 'antd';
import axios from 'axios';

import { getTestLines } from '../lib/getTestLines.tsx';

import { testQueries } from '@/entities/test';

import {
  AxiosErrorBoundary,
  MultilineCard,
  NoContent,
  WidgetSkeleton,
} from '@/shared/components';

const widgetTitle = (
  <Typography.Title level={4}>
    <Trans>Зачет</Trans>
  </Typography.Title>
);

export const Test = () => {
  const { data, isPending, error } = useQuery(testQueries.closest());

  if (!isPending && data && Object.keys(data).length > 0) {
    return (
      <Flex vertical gap={24}>
        {widgetTitle}

        <MultilineCard title='Период зачетов' lines={getTestLines(data)} />
      </Flex>
    );
  }

  if (error && axios.isAxiosError(error)) {
    return (
      <Flex vertical gap={24}>
        {widgetTitle}
        <AxiosErrorBoundary error={error} />
      </Flex>
    );
  }

  if (!isPending && (!data || Object.keys(data).length === 0)) {
    return (
      <Flex vertical gap={24}>
        {widgetTitle}

        <NoContent
          title='Ближайшие зачеты отсутствуют'
          description='Обратитесь к куратору для уточнения информации'
        />
      </Flex>
    );
  }

  return <WidgetSkeleton />;
};
