import { ConfigProvider } from 'antd';
import ru_RU from 'antd/locale/ru_RU';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import timezone from 'dayjs/plugin/timezone';
import { ReactNode } from 'react';

import { useThemeStore } from '@/shared/theme';

import 'dayjs/locale/ru';

dayjs.extend(timezone);
dayjs.extend(customParseFormat);
dayjs.locale('ru');

export const withAntConfigProvider = (component: () => ReactNode) => () => {
  const getAntdThemeConfig = useThemeStore.use.getAntdThemeConfig();

  return (
    <ConfigProvider locale={ru_RU} theme={getAntdThemeConfig()} prefixCls='ins'>
      {component()}
    </ConfigProvider>
  );
};
