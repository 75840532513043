const getWordDeclension = (
  number: number,
  firstWord: string,
  secondWord: string,
  thirdWord: string,
) => {
  if (number >= 5 && number <= 20) return firstWord;
  else if (number % 10 === 1 && number % 100 !== 11) return secondWord;
  else if (
    number % 10 > 1 &&
    number % 10 < 5 &&
    (number % 100 < 10 || number % 100 >= 20)
  )
    return thirdWord;
  else return firstWord;
};

export const getLessonsWord = (lessons: number) => {
  return getWordDeclension(lessons, 'занятий', 'занятие', 'занятия');
};

export const getDaysWord = (days: number) => {
  return getWordDeclension(days, 'дней', 'день', 'дня');
};
