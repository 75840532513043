import { QueryClient } from '@tanstack/react-query';
import { createRootRouteWithContext } from '@tanstack/react-router';

import { RootPage } from '@/pages/root';

import { TProfileStore } from '@/entities/profile';

export interface MyRouterContext {
  queryClient: QueryClient;
  profileStore: TProfileStore;
}

export const Route = createRootRouteWithContext<MyRouterContext>()({
  component: RootPage,
});
