import { http } from '../client';

export type TScheduleLessonDTO = {
  id: number;
  discipline: string;
  teacher: string;
  online: boolean;
  route: string;
  period: string;
  exam: 0 | 1;
};
export const fetchScheduleToday = async () => {
  const response = await http.get<TScheduleLessonDTO[]>('widgets/schedule');
  return response.data;
};
export const fetchScheduleLesson = async (lessonId: string) => {
  const response = await http.get<TScheduleLessonDTO>(`schedule/${lessonId}`);
  return response.data;
};
