import { Typography } from 'antd';

import { TClosestTestDTO } from '@/shared/api';
import { getDaysWord } from '@/shared/lib';

export const getTestLines = (test: TClosestTestDTO) => {
  return [
    {
      label: 'Начало',
      value: test.start,
    },
    {
      label: 'Конец',
      value: test.finish,
    },
    {
      label: <Typography.Text type='secondary'>Дней до начала</Typography.Text>,
      value: (
        <Typography.Text type='secondary'>
          {test.daysUntilStart} {getDaysWord(test.daysUntilStart)}
        </Typography.Text>
      ),
    },
  ];
};
