import { Trans } from '@lingui/macro';
import { Avatar, Button, Flex, Typography } from 'antd';
import { memo } from 'react';

import { QuestionOutlined } from '@ant-design/icons';

import { useStyles } from './styles.ts';

import bgImage from '@/shared/assets/images/img_support-bg.png';
import { externalLinks } from '@/shared/config';

export const Support = memo(() => {
  const { styles } = useStyles(bgImage);

  return (
    <Flex vertical gap={24} className={styles.support}>
      <Avatar
        shape='circle'
        icon={<QuestionOutlined />}
        size={46}
        className={styles.icon}
      />

      <Flex vertical gap={8} align='center'>
        <Typography.Title level={3} className={styles.title}>
          <Trans>Вопросы по системе?</Trans>
        </Typography.Title>
        <Typography.Text className={styles.description}>
          <Trans>
            Напишите в службу поддержки и мы ответим в течение суток
          </Trans>
        </Typography.Text>
      </Flex>

      <a href={externalLinks.tgSupport} target='_blank' rel='noreferrer'>
        <Button size='large' block>
          <Trans>Написать поддержке</Trans>
        </Button>
      </a>
    </Flex>
  );
});
