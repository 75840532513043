import { createStyles } from 'antd-style';

export const useStyles = createStyles(
  ({ css, token, prefixCls }, bgImage: string) => ({
    support: css`
      width: 100%;
      height: auto;
      background-image: url(${bgImage});
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      border-radius: 12px;
      position: relative;
      padding: 48px 16px 24px 16px;
      margin-top: 23px;
    `,
    icon: css`
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: -23px;
      background-color: ${token.colorPrimary};
      border: 2px solid ${token.colorBgBase};
    `,
    title: css`
      text-align: center;

      &.${prefixCls}-typography {
        color: ${token.colorTextLightSolid};
      }
    `,
    description: css`
      text-align: center;
      color: ${token.colorTextLightSolid};
      font-size: ${token.fontSizeLG}px;
    `,
  }),
);
