import { createStyles } from 'antd-style';

export const useStyles = createStyles(({ css, prefixCls }) => ({
  empty: css`
    margin: 0;

    & .${prefixCls}-empty-image {
      height: 117px;
      width: 200px;
      margin: 0 auto;
    }
    & .${prefixCls}-empty-image svg {
      height: 117px;
      width: 200px;
    }
  `,
}));
