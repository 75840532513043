import { http } from '@/shared/api';

export type TClosestTestDTO = {
  start: string;
  finish: string;
  daysUntilStart: number;
};

export const fetchClosestTest = async () => {
  const response = await http.get<TClosestTestDTO>('widgets/test');
  return response.data;
};
