import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ReactNode } from 'react';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: false,
      staleTime: 5 * 60 * 1000,
      gcTime: 5 * 60 * 1000,
    },
  },
});

export const withQueryClientProvider = (Component: () => ReactNode) => () => (
  <QueryClientProvider client={queryClient}>
    <Component />
    {/* {import.meta.env.MODE === 'development' && (*/}
    {/*  <ReactQueryDevtools initialIsOpen={false} />*/}
    {/* )}*/}
  </QueryClientProvider>
);
