import { createStyles } from 'antd-style';

export const useStyles = createStyles(({ css, token }) => ({
  header: css`
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${token.colorBorderSecondary};
    border-radius: 0 0 ${token.borderRadiusLG}px ${token.borderRadiusLG}px;
  `,
  container: css`
    width: 100%;
  `,
}));
