import { queryOptions } from '@tanstack/react-query';

import { fetchAnnounce, fetchLastAnnounces } from '@/shared/api';

export const announceQueries = {
  last: () =>
    queryOptions({
      queryKey: ['announce', 'widget'],
      queryFn: fetchLastAnnounces,
    }),
  detail: (announceId: string) =>
    queryOptions({
      queryKey: ['announce', announceId],
      queryFn: () => fetchAnnounce(announceId),
    }),
};
